// export const paymentDetails={
//     gpay:"fcbizopiskn@freecharge",
//     paytm:"fcbizopiskn@freecharge"
// }
// Batham

// export const paymentDetails={
//     gpay:"7797301257@okbizaxis",
//     paytm:"7797301257@okbizaxis"
// }

export const paymentDetails={
    gpay:"8278080377-1@okbizaxis",
    paytm:"8278080377-1@okbizaxis"
}